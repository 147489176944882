import React from "react";
import Helmet from "react-helmet";
import {TryCodecks, ExplainCodecks} from "./TryCodecks";
import "../lib/base-styles.css";
import socialMediaImg from "../imgs/social-media-card.png";
import socialMediaTwitterImg from "../imgs/social-media-twitter-card.png";
import CodeInfoHint from "../features/affiliate/CodeInfoHint";
import {XCol} from "./xui/Box";
import Footer from "./Footer";
import {themePurple} from "../page-styles/landingpage.css";

// const unityBadge = (
//   <TopBadge
//     storageKey="seen-unity-badge"
//     button={{href: "https://manual.codecks.io/user-reports/", label: "To the manual"}}
//   >
//     <Text color="white" preset="bold" size={2}>
//       Just out:
//     </Text>
//     <Text color="purple100" preset="bold" size={2} align="center">
//       User feedback directly from your game via Unity Integration or API
//     </Text>
//   </TopBadge>
// );

const DefaultLayout = ({
  children,
  title,
  description,
  keywords,
  footer = "try",
  canonicalUrl,
  location,
  headerContent,
  theme = themePurple,
  img,
  addScripts = [],
}) => (
  <XCol minHeight="fullScreen" className={theme}>
    {/* <CodeInfoHint fallback={unityBadge} /> */}
    <CodeInfoHint />
    <Helmet
      titleTemplate="%s - Codecks"
      title={title || "Playful Project Management for Game Development"}
      htmlAttributes={{lang: "en"}}
    >
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {canonicalUrl && (
        <link rel="canonical" href={`${process.env.GATSBY_SITE_URL}${canonicalUrl}`} />
      )}
      <meta
        property="og:url"
        content={`${process.env.GATSBY_SITE_URL}${canonicalUrl || location.pathname}`}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={title || "Playful Project Management for Game Devs by Game Devs"}
      />
      <meta
        property="og:description"
        content={
          description ||
          "Keep your head cool and deliver your next milestone in a playful interface inspired by collectible card games."
        }
      />
      <meta
        property="og:image"
        content={`${process.env.GATSBY_SITE_URL}${img || socialMediaImg}`}
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@codecks_io" />
      <meta
        name="twitter:image"
        content={`${process.env.GATSBY_SITE_URL}${img || socialMediaTwitterImg}`}
      />
      <meta name="twitter:title" content={title || "For Game Devs by Game Devs"} />
      {addScripts.map(src => (
        <script key={src} src={src} async />
      ))}
      {headerContent}
    </Helmet>
    <XCol fillParent>{children}</XCol>
    {footer === "try" ? <TryCodecks /> : footer === "explain" ? <ExplainCodecks /> : null}
    <Footer />
  </XCol>
);

export default DefaultLayout;
