import React, {forwardRef} from "react";
import {HeaderThemeSentinel} from "../Header";
import {Box, XCol, XStrip} from "../xui/Box";

export const ThemeBox = forwardRef(
  (
    {
      outerChild,
      customTitle,
      title,
      children,
      theme,
      withBorder,
      bg,
      py = 9,
      sp = 9,
      innerPy = 7,
      width = "xxxl",
      fillParent,
      justify,
      id,
      withOverflow,
      outerProps,
    },
    ref
  ) => {
    const content = (
      <>
        {customTitle
          ? customTitle
          : title && (
              <Box as="h2" textAlign="center" size="xxxl" color="primary">
                {title}
              </Box>
            )}
        {children}
      </>
    );

    return (
      <XStrip
        width={width}
        py={py}
        sp={withBorder ? null : sp}
        outerChild={
          <>
            {theme && <HeaderThemeSentinel theme={theme} />}
            {outerChild}
          </>
        }
        bg={bg || "bgBack"}
        className={theme}
        noOverflow={!withOverflow}
        fillParent={fillParent}
        justify={!withBorder && justify}
        id={id}
        ref={ref}
        {...outerProps}
      >
        {withBorder ? (
          <XCol
            sp={sp}
            borderWidth="1"
            borderColor="bgBorder"
            px={8}
            py={innerPy}
            sm_px={4}
            rounded="lg"
            fillParent={fillParent}
            justify={justify}
          >
            {content}
          </XCol>
        ) : (
          content
        )}
      </XStrip>
    );
  }
);

export default ThemeBox;
