import React from "react";
import {Link} from "gatsby";
import {isExternalUrl} from "../lib/utils";

const LinkOrButton = React.forwardRef(({forwardedRef, disabled, ...rest}, ref) => {
  if (rest.as) {
    const Comp = rest.as;
    return <Comp {...rest} disabled={disabled} />;
  } else if ("to" in rest) {
    if (isExternalUrl(rest.to)) {
      const {to, ...restProps} = rest;
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return <a ref={ref} href={to} target="_blank" rel="noopener noreferrer" {...restProps} />;
    } else {
      const props = {
        innerRef: ref,
        ...rest,
        ...(disabled ? {onClick: e => e.preventDefault()} : {}),
      };
      return <Link {...props} />;
    }
  } else if ("href" in rest) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a ref={ref} {...rest} />;
  } else {
    return <button ref={ref} disabled={disabled} type="button" {...rest} />;
  }
});

export default LinkOrButton;
